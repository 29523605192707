import { Col } from "antd";

export const Hero = () => {
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ margin: "10px", width: "100%", textAlign: "center" }}>
        <img
          style={{ maxWidth: "900px", width: "100%" }}
          src={
            "https://cdn.shopify.com/s/files/1/0136/6943/3398/collections/Homeware_Banner.jpg?v=1622488110"
          }
          alt="table with a collection of homeware items on display "
        />
      </div>
    </Col>
  );
};
