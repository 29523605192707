import { Row } from "antd";
import { Hero } from "../components/landingPage/hero";

import { ProductRange } from "../components/landingPage/productRange";

// import { Location } from "../components/landingPage/location";

export const LandingPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#f4f1ed",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Row style={{ maxWidth: "900px" }}>
        <Hero />
        <ProductRange />
        {/* <Location /> */}
      </Row>
    </div>
  );
};
