import { Button } from "antd";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import logo from "../../images/logo.svg";
import { WhatsAppOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
// import { MainMenu } from "./mainMenu";

export const NavBar = () => {
  return (
    <Navbar sticky="top">
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand>
            {"Karuna Trading"}
            {/* <img
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-tech-for-good/Learn/tfg-logo-01.png"
              }
              className="d-inline-block align-top"
              height="40"
              alt="tech for good logo"
            /> */}
          </Navbar.Brand>
        </Link>
        <Nav className="justify-content-end">
          {/* <MainMenu />
           */}
          <Button
            size="large"
            type="link"
            className="mainFont"
            style={{
              backgroundColor: "black",
              color: "white",
            }}
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/94771094199?text=Let%20me%20know%20more%20about%20your%20products!"
          >
            <WhatsAppOutlined style={{ verticalAlign: "middle" }} />
            {"WhatsApp Us"}
          </Button>
        </Nav>
      </Container>
    </Navbar>
  );
};
