export const Location = () => {
  return (
    <iframe
      id="googleMap"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d698.2349878916864!2d79.85485705096013!3d6.939154193757408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2591e42689c25%3A0xe8b875aaa3a48191!2sKaruna%20Trading%20Centre!5e0!3m2!1sen!2slk!4v1696083590903!5m2!1sen!2slk"
      width="100%"
      height="auto"
      title="Google Map of PMN"
      style={{ border: "0", marginBottom: "15px" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
