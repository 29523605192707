import { Button, Col, Row } from "antd";
import { PhoneFilled } from "@ant-design/icons";

export const BottomNavBar = () => {
  return (
    <Row style={{ width: "100%", position: "sticky", bottom: 0 }}>
      {/* <Col span={12} style={{ textAlign: "right" }}>
        <Button size="large" style={{ width: "100%" }}>
          Get Product Info
        </Button>
      </Col> */}
      <Col span={24} style={{ textAlign: "left" }}>
        <a style={{ textDecoration: "none" }} href="tel:+94771094199">
          <Button
            size="large"
            style={{ width: "100%", padding: "", height: "60px" }}
          >
            <PhoneFilled
              style={{ fontSize: "30px", verticalAlign: "baseline" }}
            />
            <span className="mainFont" style={{ fontSize: "20px" }}>
              {"Contact Us"}
            </span>
          </Button>
        </a>
      </Col>
    </Row>
  );
};
