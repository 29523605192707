import { Col, Divider, Row } from "antd";
import { ContactUs } from "../landingPage/contactUs";
// import { Link } from "react-router-dom";
import { Location } from "../landingPage/location";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div
      style={{
        backgroundColor: "#f4f1ed",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Row gutter={[18, 18]} style={{ margin: "20px", maxWidth: "900px" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={12} style={{ fontFamily: "Montserrat" }}>
          <p style={{ fontWeight: "900" }}>{"Kuruna Trading Center"}</p>
          <p style={{ fontSize: "14px" }}>
            <b>Opening Times</b>
          </p>
          <p>{"Mon to Sat - 8.00am - 6.30pm"}</p>
          <p>{"Sunday - CLOSED"}</p>
          <small>
            <p>{"46 Dam St, Colombo 12, Sri Lanka"}</p>
          </small>
          <Location />
        </Col>
        {/* <Col xs={12} sm={12} md={4} style={{ fontFamily: "Montserrat" }}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://tfg.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <Link
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                to={"/assets"}
              >
                Assets
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={4} style={{ fontFamily: "Montserrat" }}>
          <p style={{ fontWeight: "700" }}>Community</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://www.linkedin.com/company/techforgood-lk/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col> */}

        <Col xs={24} sm={24} md={12} style={{ fontFamily: "Montserrat" }}>
          <ContactUs />
        </Col>
        <Divider style={{ margin: "0px" }}></Divider>
        <Col span={24} style={{ fontFamily: "Montserrat" }}>
          <div style={{ textAlign: "center" }}>
            {"Kuruna Trading Center Pvt Ltd"} &copy; {year}
          </div>
        </Col>
      </Row>
    </div>
  );
};
