import { Col, Row } from "antd";

export const ProductRange = () => {
  const productRangeData = [
    {
      key: 1,
      title: "Glassware",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Glassware.png",
      url: "",
    },
    {
      key: 2,
      title: "Flatware",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Flatware.png",
      url: "",
    },
    {
      key: 3,
      title: "Dining",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Dining.png",
      url: "",
    },
    {
      key: 4,
      title: "Buffetware",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Buffetware.png",
      url: "",
    },
    {
      key: 4,
      title: "Barware",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Barware.png",
      url: "",
    },
    {
      key: 4,
      title: "Utensils",
      image:
        "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-karuna/Utensils.png",
      url: "",
    },
  ];

  const listProductRange = productRangeData.map((data) => {
    return (
      <Col
        xs={6}
        sm={6}
        md={4}
        key={data.key}
        style={{ padding: "0px 2px", textAlign: "center" }}
      >
        <div
          style={{
            background: "white",
            height: "100%",
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          <img alt="icon" src={data.image} />
          <div>
            <span style={{ textAlign: "center" }}>
              <small>{data.title}</small>
            </span>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <Row gutter={[14, 14]} style={{ margin: "10px" }}>
      <Col span={24} className="mainFont">
        <h2>Our Product Range:</h2>
      </Col>
      {listProductRange}
    </Row>
  );
};
